<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-select class="p-select" :model="filter" :limit="2" labelWidth="80px"
                @select="handleQuery" @reset="handleQuery">
        <el-form-item prop="merchandiseName" label="中类名称" slot="1">
          <el-input v-model.trim="filter.merchandiseName"></el-input>
        </el-form-item>
        <el-form-item prop="taxClassName" label="税收分类" slot="1">
          <el-input v-model.trim="filter.taxClassName"></el-input>
        </el-form-item>
        <el-form-item prop="confirmStatus" label="确认状态" slot="1">
          <el-select v-model="filter.confirmStatus">
            <el-option v-for="i in confirmStatuses" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="codeType" label="赋码方式" slot="2">
          <el-select v-model="filter.codeType">
            <el-option v-for="i in merchandiseCodeTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="createTime" label="创建时间" slot="2">
          <el-date-picker v-model="filter.createTime" type="daterange" range-separator="-"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item prop="dataFrom" label="数据来源" slot="2">
          <el-select v-model="filter.dataFrom">
            <el-option v-for="i in dataFroms" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </p-select>
    </div>
    <div class="p-content">
      <div class="p-table">
        <p-header title="中类列表">
          <el-button type="primary" @click="handleConfirmBatch">批量确认</el-button>
          <el-button type="primary" @click="handleShowInsert">新增中类</el-button>
        </p-header>
        <el-table border stripe v-loading="tableLoading" :data="tableData"
                  @selection-change="handleSelection" :header-cell-style="handleHeaderCellStyle">
          <el-table-column type="selection" width="50" fixed="left"/>
          <el-table-column type="index" label="序号" width="60"/>
          <el-table-column prop="uniqueCode" label="中类编码" width="110"/>
          <el-table-column prop="merchandiseName" label="中类名称" min-width="140"/>
          <el-table-column prop="taxClassName" label="税收分类名称" min-width="180"/>
          <el-table-column prop="taxClassCode" label="税收分类编码" min-width="140"/>
          <el-table-column prop="unit" label="单位" min-width="80"/>
          <el-table-column prop="taxRate" label="税率" :formatter="fmtColumn" min-width="140"/>
          <el-table-column prop="confirmStatus" label="确认状态" :formatter="fmtColumn"/>
          <el-table-column prop="codeType" label="赋码方式" :formatter="fmtColumn" min-width="140"/>
          <el-table-column prop="created" label="创建时间" :formatter="fmtColumn" min-width="140"/>
          <el-table-column prop="updated" label="更新时间" :formatter="fmtColumn" min-width="140"/>
          <el-table-column prop="dataFrom" label="数据来源" :formatter="fmtColumn" min-width="140"/>
          <el-table-column prop="operate" label="操作" width="90" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="medium" @click="handleShowEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="p-page">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleQuery"
                       :current-page.sync="filter.page"
                       :page-sizes="[15, 30, 50, 100]"
                       :page-size="filter.size"
                       layout="total, sizes, prev, pager, next"
                       :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 编辑中类弹窗 -->
    <el-dialog append-to-body :visible.sync="editVisible" :title="title" :show-close="false" @close="handleCancel"
               width="428px">
      <div class="dialog-wrap">
        <el-form class="dialog-content" ref="editInfo" :rules="editRules" :model="editInfo" label-position="top">
          <el-form-item prop="orgId" label="组织">
            <organization-select :model="editInfo" allLabel="请选择组织"></organization-select>
          </el-form-item>
          <el-form-item prop="merchandiseName" label="中类名称">
            <el-input v-model="editInfo.merchandiseName"></el-input>
          </el-form-item>
          <el-form-item prop="taxClassCode" label="税收分类编码">
            <div class="tax-class-code" @click="taxClassVisible = true">
              <span>{{editInfo.taxClassCode}}</span>
              <i class="el-icon-more"></i>
            </div>
          </el-form-item>
          <el-form-item prop="taxClassName" label="税收分类名称">
            <el-input v-model="editInfo.taxClassName"></el-input>
          </el-form-item>
          <el-form-item prop="unit" label="单位">
            <el-input v-model="editInfo.unit"></el-input>
          </el-form-item>
          <el-form-item prop="taxRate" label="税率">
            <el-select v-model="editInfo.taxRate">
              <el-option v-for="i in taxRates" :key="i.value" :value="i.value" :label="i.label"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button type="default" @click="handleCancel">取消</el-button>
          <el-button type="primary" @click="handleCheck">保存</el-button>
        </div>
      </div>
      <el-dialog append-to-body title="选择税收分类编码" :visible.sync="taxClassVisible" :show-close="false">
        <tax-class-code-mapping @handleCurrentGoods="handleCurrent"></tax-class-code-mapping>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { pagingMerchandise, updateMerchandise, insertMerchandise, confirmBatch } from '@/service/merchandise'
import { tableStyle } from '@/util/mixins'
import PHeader from '@/components/pdt/PHeader'
import PSelect from '@/components/pdt/PSelect'
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import TaxClassCodeMapping from '../merchandise/TaxClassCodeMapping'
import { MERCHANDISE_TAX_RATE } from '@/content/merchandiseInfo'

const EditInfo = {
  id: null,
  orgId: '',
  bizType: '1',
  uniqueCode: '',
  merchandiseName: '',
  taxClassName: '',
  taxClassCode: '',
  taxRate: '',
  unit: undefined,
}

export default {
  name: 'SysMtgl',
  components: { PSelect, PHeader, OrganizationSelect, TaxClassCodeMapping },
  mixins: [tableStyle],
  data () {
    return {
      title: '',
      tableLoading: false,
      editVisible: false,  // 编辑弹窗
      taxClassVisible: false, //  税收分类弹窗
      taxRates: MERCHANDISE_TAX_RATE,
      tableData: [],
      selections: [],  // 勾选数据
      confirmStatuses: [],  // 确认状态
      merchandiseCodeTypes: [], // 赋码方式
      dataFroms: [], // 数据来源
      total: 0,
      editInfo: JSON.parse(JSON.stringify(EditInfo)),
      editRules: {
        taxClassName: [{ required: true, message: '请输入税收分类名称', trigger: 'blur' }],
        taxClassCode: [{ required: true, message: '请输入税收分类编码', trigger: 'blur' }],
        taxRate: [{ required: true, message: '请选择税率', trigger: 'blur' }]
      },
      filter: {
        orgId: localStorage.getItem('orgId'),
        bizType: '1',
        merchandiseName: '',
        confirmStatus: '',
        codeType: '',
        dataFrom: '',
        taxClassName: '',
        createTime: '',
        page: 1,
        size: 15
      }
    }
  },
  created () {
    this.initDict()
    this.handleQuery()
  },
  methods: {
    // 初始化字典
    initDict () {
      let all = { value: '', label: '全部' }
      this.getDicts('m_code_type').then(({ data }) => this.merchandiseCodeTypes = [all, ...data])
      this.getDicts('m_confirm_status').then(({ data }) => this.confirmStatuses = [all, ...data])
      this.getDicts('m_data_from').then(({ data }) => this.dataFroms = [all, ...data])
    },
    // 查询
    async handleQuery (val) {
      this.filter.page = 1
      if (val) this.filter.page = val
      if (this.filter.createTime && this.filter.createTime.length > 1) {
        this.filter['startTime'] = this.filter.createTime[0]
        this.filter['endTime'] = this.filter.createTime[0]
      } else {
        delete this.filter.startTime
        delete this.filter.endTime
      }
      this.tableLoading = true
      const { success, data } = await pagingMerchandise(this.filter)
      this.tableLoading = false
      if (success) {
        this.tableData = data.records
        this.total = data.total
      }
    },
    // 保存编辑结果
    handleCheck () {
      this.$refs.editInfo.validate((valid) => {
        if (!valid) return
        this.handleSave()
      })
    },
    async handleSave () {
      this.editInfo.uniqueCode = this.editInfo.merchandiseName
      const { success } = this.editInfo.id ? await updateMerchandise(this.editInfo) : await insertMerchandise(this.editInfo)
      if (success) {
        this.toast('商品中类保存成功', 'success')
        this.handleCancel()
      }
    },
    // 选中税收分类信息
    handleCurrent (row) {
      this.editInfo.taxClassCode = row.taxClassCode
      this.editInfo.taxClassName = row.taxClassName
      this.editInfo.taxRate = new Number(row.taxRate.replace('%', '')) / 100
      if (row.taxPreferentialContent != '') {
        this.editInfo.isTaxPreferential = '1'
        this.editInfo.taxPreferentialContent = row.taxPreferentialContent
      }
      this.taxClassVisible = false
    },
    // 取消保存
    handleCancel () {
      this.$refs.editInfo.clearValidate()
      this.editInfo = JSON.parse(JSON.stringify(EditInfo))
      this.editVisible = false
      this.handleQuery()
    },
    // 切换分页条数
    handleSizeChange (val) {
      this.filter.size = val
      this.handleQuery()
    },
    // 新增 - 展开弹窗
    handleShowInsert () {
      this.editVisible = true
      this.title = '新增中类'
    },
    // 编辑 - 展开弹窗
    handleShowEdit (row) {
      this.editInfo = row
      this.editVisible = true
      this.title = '编辑中类'
    },
    handleSelection (val) {
      this.selections = val
    },
    // 批量确认
    async handleConfirmBatch () {
      if (this.selections.length < 1) return this.toast('未选中数据', 'warning')

      const index = this.selections.findIndex(i => i.confirmStatus == '1')
      if (index > -1) return this.toast('当前勾选列表存在已确认商品行', 'warning')

      const { success, message } = await confirmBatch({ ids: this.selections.map(i => i.id) })
      if (success) this.toast('商品批量确认成功', 'success', () => this.handleQuery())
      else this.toast(message, 'error')
    },
    // 格式化表格列
    fmtColumn (row, column) {
      switch (column.property) {
        case 'taxRate':
          if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
            return row.taxPreferentialContent
          }
          return row.taxRate * 100 + '%'
        case 'confirmStatus':
          return this.getDictLabel(this.confirmStatuses, row.confirmStatus)
        case 'created':
          return this.$moment(row.created).format('YYYY-MM-DD')
        case 'updated':
          return this.$moment(row.updated).format('YYYY-MM-DD')
        case 'dataFrom':
          return this.getDictLabel(this.dataFroms, row.dataFrom)
        case 'codeType':
          return this.getDictLabel(this.merchandiseCodeTypes, row.codeType)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .p-content {
    padding: 0 20px 20px;
  }

  .p-select {
    padding: 24px;

    ::v-deep .el-form-item__label {
      text-align: left;
    }
  }

  .dialog-wrap {
    margin-top: -40px;
    padding: 24px;

    .dialog-content {
      .el-form-item {
        padding-bottom: 16px;
        text-align: left;

        .el-select, ::v-deep .el-input, .el-input__inner {
          width: 380px;
        }
      }

      ::v-deep .el-form-item__error {
        top: 32px;
      }

      .tax-class-code {
        height: 32px;
        width: 350px;
        display: flex;
        padding: 0 15px;
        border-radius: 4px;
        align-content: center;
        border: 1px solid #DCDFE6;
        justify-content: space-between;

        i {
          color: #3D94FF;
          line-height: 32px;
          margin-right: -4px;
        }
      }
    }

    .dialog-footer {
      margin-top: 16px;
      text-align: right;
    }
  }

</style>
